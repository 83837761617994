import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticateStore } from '@etop/core';
import { Subject } from 'rxjs';

const CURRENCY = [' triệu tỷ', ' nghìn tỷ', ' tỷ', ' triệu', ' nghìn', ''];
const NUM_TEXT = [' không', ' một', ' hai', ' ba', ' bốn', ' năm', ' sáu', ' bảy', ' tám', ' chín'];

@Injectable()
export class UtilService {
  isMobile = false;
  isMedium = false;
  version: string;
  banners = [];
  onScreenWidthChanged = new Subject();

  TOTAL_COD_EXPLAIN = '*Tổng tiền thu hộ đã cấn trừ chi phí của các đơn hàng đang được xử  lý (chưa giao hàng hoặc chưa trả hàng thành công)';
  ACTUAL_BALANCE_EXPLAIN = 'Tổng tiền thu hộ <strong>đã thu thành công</strong> cần trừ phí của các đơn giao hàng chưa đối soát.';
  AVAILABLE_BALANCE_EXPLAIN = 'Tổng tiền thu hộ của <strong> tất cả đơn giao hàng </strong> (khác hủy) cấn trừ chi phí của các đơn hàng chưa đối soát.';
  COMPANY_INFO_WARNING = '*Vui lòng nhập thông tin chính xác nếu bạn là doanh nghiệp và có nhu cầu xuất hoá đơn.';

  constructor(
    private httpService: HttpService,
    private sanitizer: DomSanitizer,
    private auth: AuthenticateStore
  ) {
    this.isMedium = window.innerWidth < 1024;
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', () => {
      const { innerWidth } = window;
      this.isMobile = innerWidth < 768;
      this.onScreenWidthChanged.next(innerWidth);
    });
    window.addEventListener('resize', () => {
      const { innerWidth } = window;
      this.isMedium = window.innerWidth < 1024;
      this.onScreenWidthChanged.next(innerWidth);
    });
  }

  stripHTML(html: string) {
    const _tempDIV = document.createElement('div');
    _tempDIV.innerHTML = html;
    return _tempDIV.textContent || _tempDIV.innerText || '';
  }

  removeNewLineCharacters(str) {
    return str.trim().replace(/\r?\n|\r/g, '');
  }

  moneyToText(amount: number) {
    let calc = Math.abs(amount);
    const arr_text = [];
    const positions = [];
    const divisors = [1000000000000000, 1000000000000, 1000000000, 1000000, 1000, 1];
    if (calc === 0) {
      return 'Không đồng';
    }
    // Too large amount
    if (calc > 8999999999999999) {
      return (amount < 0 ? 'Âm ' : '') + calc.toString() + ' đồng';
    }
    divisors.forEach(divisor => {
      // console.log('calc', calc);
      const three_dig = Math.floor(calc / divisor) || 0;
      // console.log('divisor', divisor);
      // console.log('three_dig', three_dig);
      positions.push(three_dig);
      calc = Math.max(calc - three_dig * divisor, 0);
    });
    // console.log('positions', positions);

    positions.forEach((value, index) => {
      const text = UtilService.threeDigit2Text(value, arr_text.length === 0);
      if (text) {
        arr_text.push(text + CURRENCY[index]);
      }
    });
    // console.log('arr_text', arr_text);
    let result = ((amount < 0 ? 'Âm' : '') + arr_text.join(',') + ' đồng').trim();
    if (amount % 1000 == 0) {
      result += ' chẵn';
    }
    return result.substring(0, 1).toUpperCase() + result.substring(1);
  }

  private static threeDigit2Text(three_digit_no: number, is_first: boolean = true) {
    let result = '';
    const units = three_digit_no % 10;
    const tens = Math.floor((three_digit_no % 100) / 10);
    const hundreds = Math.floor(three_digit_no / 100);
    // console.log('units ', units);
    // console.log('tens ', tens);
    // console.log('hundreds ', hundreds);
    if (hundreds ===  0 && tens === 0 && units === 0) {
      return '';
    }
    if (hundreds !== 0 || !is_first) {
      result += NUM_TEXT[hundreds] + ' trăm';
      if (tens === 0 && units !== 0) {
        result += ' lẻ';
      }
    }
    if (tens !== 0 && tens !== 1) {
      result += NUM_TEXT[tens] + ' mươi';
    }
    if (tens === 1) {
      result += ' mười';
    }
    switch (units) {
      case 1:
        if (tens !== 0 && tens !== 1) {
          result += ' mốt';
        } else   {
          result += NUM_TEXT[units];
        }
        break;
      case 5:
        if (tens === 0) {
          result += NUM_TEXT[units];
        } else {
          result += ' lăm';
        }
        break;
      default:
        if (units !== 0) {
          result += NUM_TEXT[units];
        }
        break;
    }
    return result;
  }

  deepClone<TClone>(obj: TClone): TClone | any {
    return JSON.parse(JSON.stringify(obj));
  }

  checkMobileVersion() {
    this.isMobile = document.body.offsetWidth < 768;
    window.addEventListener("resize", () => {
      this.isMobile = document.body.offsetWidth < 768
    })
  }

  checkMediumVersion(){
    this.isMedium = document.body.offsetWidth < 1024;
    window.addEventListener("resize", () => {
      this.isMedium = document.body.offsetWidth < 1024;
    })
  }

  keepHtmlCssStyle(html) {
    let _html = this.sanitizer.bypassSecurityTrustHtml(html);
    return _html;
  }

  safeUrl(url) {
    let _url = this.sanitizer.bypassSecurityTrustUrl(url);
    return _url;
  }

  uuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }

  /**
   * @deprecated
   */
  getSlug() {
    return this.auth.snapshot.account.url_slug || this.auth.currentAccountIndex();
  }

  trimFields(obj, fields: string[]) {
    fields.forEach(f => {
      obj[f] = obj[f] && obj[f].trim();
    });
    return obj;
  }

  cleanNumberString(str) {
    return str.replace(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w]+/g, '');
  }

  numberOnly(event, allow_negative = false) {
    const num = Number(event.key);
    if (allow_negative && event.key == '-') {
      return true;
    }
    if (Number.isNaN(num)) {
      event.preventDefault();
      return false;
    }
  }

  async getEtopContent(item_id?) {
    try {
      const res = await fetch('https://cms-setting.etop.vn/api/get-banner').then(r =>
        r.json()
      );
      if (item_id || item_id == 0) {
        return res.data[item_id].url;
      }
      return res.data;
    } catch (e) {
      return [];
    }
  }

  formatName(name: string) {
    return this.makeSearchText(name).toUpperCase();
  }

  validatePhoneNumber(phone: string) {
    if (phone && phone.match(/^0[0-9]{9,10}$/)) {
      return true;
    }
    toastr.error('Vui lòng nhập số điện thoại hợp lệ!');
    return false;
  }

  validEmail(email: string) {
    if (!email) {
      toastr.error('Vui lòng nhập email hợp lệ!');
      return false;
    }
    if (email.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      return true;
    }
    toastr.error('Vui lòng nhập email hợp lệ!');
    return false;
  }

  removeDiacritic(str) {
    str = str?.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str?.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str?.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str?.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str?.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str?.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str?.replace(/đ/g, 'd');
    str = str?.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str?.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str?.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str?.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str?.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str?.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str?.replace(/Đ/g, 'D');
    return str;
  }

  makeSearchText(str: string) {
    str = str.trim();
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    return str;
  }

  createHandle(str) {
    if (str) {
      str = str.trim();
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
      str = str.replace(/đ/g, 'd');
      str = str.replace(/\,/g, '-');
      str = str.replace(/\_/g, '-');
      str = str.replace(/\./g, '-');
      str = str.replace(/\!/g, '-');
      str = str.replace(/\?/g, '-');
      str = str.replace(/\~/g, '-');
      str = str.replace(/\ /g, '-');
      str = str.replace(/\|/g, '-');
      str = str.replace(/\./g, '-');
      str = str.replace(/\"/g, '-');
      str = str.replace(/\'/g, '-');
      str = str.replace(/\s+/g, '-');
      str = str.replace(/[^\w\-]+/g, '');
      str = str.replace(/\-\-+/g, '-');
      str = str.replace(/^-+/, '');
      str = str.replace(/-+$/, '');
      if (str.slice(-1) == '-') {
        str = str.substring(0, str.length - 1);
      }
    }
    return str;
  }

  isEmptyObject(obj): boolean {
    if (!obj || (Object.keys(obj).length === 0 && obj.constructor === Object)) {
      return true;
    }
    return false;
  }

  isDuplicateExists(arr){ // kiem tra xem gia tri trong mang co trung hay kkhong?
    return new Set(arr).size !== arr.length
  }

  fileToImage(file) {
    return new Promise((resolve, _) => {
      let oFReader = new FileReader();
      oFReader.readAsDataURL(file);
      oFReader.onload = (oFREvent: any) => {
        let image = new Image();
        image.src = oFREvent.target.result;

        image.onload = (e: any) => {
          resolve(image);
        };
      };
    });
  }

  fillImage(img, width, height) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, width / 2 - img.width / 2, height / 2 - img.height / 2);
    return canvas.toDataURL();
  }

  async uploadImages(files, size, type?) {
    const formData = new FormData();
    await Promise.all(
      files.map(async file => {
        const f: any = await this.fileToImage(file);
        const minDimension = Math.min(f.naturalHeight, f.naturalWidth);
        const scale = size / minDimension;
        if (scale < 1) {
          const base64 = this.downScaleImage(f, scale, file.type);
          formData.append('files', this.dataURLtoFile(base64, file.name));
        } else if (minDimension < 200) {
          const base64 = this.fillImage(f, 200, 200);
          formData.append('files', this.dataURLtoFile(base64, file.name));
        } else {
          formData.append('files', file);
        }
        if (type) formData.append('type', type);
      })
    );
    return this.httpService.upload(formData);
  }

  searchCompare(_name, _keyword, operator: string = '&=') {
    try {
      if (typeof _keyword == 'boolean' && operator != '==') {
        return false;
      }
      if (typeof _keyword == 'boolean') {
        return _name == _keyword;
      }
      if (_name == undefined) {
        return false;
      }
      if (operator == '==') {
        // tslint:disable-next-line:no-shadowed-variable
        let name = this.removeDiacritic(_name.toString().toLowerCase()).trim();
        // tslint:disable-next-line:no-shadowed-variable
        let keyword = _keyword.toString().toLowerCase().replace(/\s+/g, ' ');
        keyword = this.removeDiacritic(keyword).trim();
        return name == keyword;
      }

      let name = this.removeDiacritic(_name.toString().toLowerCase());
      let keyword = _keyword.toString().toLowerCase().replace(/\s+/g, ' ');
      keyword = this.removeDiacritic(keyword);
      let words = keyword.split(' ');
      if (operator == '&=') {
        return words.reduce((l, r) => l && name.indexOf(r) > -1, true);
      }
      if (operator == '!=') {
        return !words.reduce((l, r) => l && name.indexOf(r) > -1, true);
      }
    } catch (e) {
      debug.error(e);
    }
  }
  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  downScaleImage(img, scale, type) {
    let imgCV = document.createElement('canvas');
    imgCV.width = img.width;
    imgCV.height = img.height;
    let imgCtx = imgCV.getContext('2d');
    imgCtx.drawImage(img, 0, 0);
    return this.downScaleCanvas(imgCV, scale, type);
  }

  downScaleCanvas(cv, scale, type) {
    if (!(scale < 1) || !(scale > 0))
      throw 'scale must be a positive number <1 ';
    var sqScale = scale * scale; // square scale = area of source pixel within target
    var sw = cv.width; // source image width
    var sh = cv.height; // source image height
    var tw = Math.floor(sw * scale); // target image width
    var th = Math.floor(sh * scale); // target image height
    var sx = 0,
      sy = 0,
      sIndex = 0; // source x,y, index within source array
    var tx = 0,
      ty = 0,
      yIndex = 0,
      tIndex = 0; // target x,y, x,y index within target array
    var tX = 0,
      tY = 0; // rounded tx, ty
    var w = 0,
      nw = 0,
      wx = 0,
      nwx = 0,
      wy = 0,
      nwy = 0; // weight / next weight x / y
    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    var crossX = false; // does scaled px cross its current px right border ?
    var crossY = false; // does scaled px cross its current px bottom border ?
    var sBuffer = cv.getContext('2d').getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
    var tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
    var sR = 0,
      sG = 0,
      sB = 0; // source's current point r,g,b
    /* untested !
    var sA = 0;  //source alpha  */

    for (sy = 0; sy < sh; sy++) {
      ty = sy * scale; // y src position within target
      tY = 0 | ty; // rounded : target pixel's y
      yIndex = 3 * tY * tw; // line index within target array
      crossY = tY != (0 | (ty + scale));
      if (crossY) {
        // if pixel is crossing botton target pixel
        wy = tY + 1 - ty; // weight of point within target pixel
        nwy = ty + scale - tY - 1; // ... within y+1 target pixel
      }
      for (sx = 0; sx < sw; sx++, sIndex += 4) {
        tx = sx * scale; // x src position within target
        tX = 0 | tx; // rounded : target pixel's x
        tIndex = yIndex + tX * 3; // target pixel index within target array
        crossX = tX != (0 | (tx + scale));
        if (crossX) {
          // if pixel is crossing target pixel's right
          wx = tX + 1 - tx; // weight of point within target pixel
          nwx = tx + scale - tX - 1; // ... within x+1 target pixel
        }
        sR = sBuffer[sIndex]; // retrieving r,g,b for curr src px.
        sG = sBuffer[sIndex + 1];
        sB = sBuffer[sIndex + 2];

        /* !! untested : handling alpha !!
           sA = sBuffer[sIndex + 3];
           if (!sA) continue;
           if (sA != 0xFF) {
               sR = (sR * sA) >> 8;  // or use /256 instead ??
               sG = (sG * sA) >> 8;
               sB = (sB * sA) >> 8;
           }
        */
        if (!crossX && !crossY) {
          // pixel does not cross
          // just add components weighted by squared scale.
          tBuffer[tIndex] += sR * sqScale;
          tBuffer[tIndex + 1] += sG * sqScale;
          tBuffer[tIndex + 2] += sB * sqScale;
        } else if (crossX && !crossY) {
          // cross on X only
          w = wx * scale;
          // add weighted component for current px
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // add weighted component for next (tX+1) px
          nw = nwx * scale;
          tBuffer[tIndex + 3] += sR * nw;
          tBuffer[tIndex + 4] += sG * nw;
          tBuffer[tIndex + 5] += sB * nw;
        } else if (crossY && !crossX) {
          // cross on Y only
          w = wy * scale;
          // add weighted component for current px
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // add weighted component for next (tY+1) px
          nw = nwy * scale;
          tBuffer[tIndex + 3 * tw] += sR * nw;
          tBuffer[tIndex + 3 * tw + 1] += sG * nw;
          tBuffer[tIndex + 3 * tw + 2] += sB * nw;
        } else {
          // crosses both x and y : four target points involved
          // add weighted component for current px
          w = wx * wy;
          tBuffer[tIndex] += sR * w;
          tBuffer[tIndex + 1] += sG * w;
          tBuffer[tIndex + 2] += sB * w;
          // for tX + 1; tY px
          nw = nwx * wy;
          tBuffer[tIndex + 3] += sR * nw;
          tBuffer[tIndex + 4] += sG * nw;
          tBuffer[tIndex + 5] += sB * nw;
          // for tX ; tY + 1 px
          nw = wx * nwy;
          tBuffer[tIndex + 3 * tw] += sR * nw;
          tBuffer[tIndex + 3 * tw + 1] += sG * nw;
          tBuffer[tIndex + 3 * tw + 2] += sB * nw;
          // for tX + 1 ; tY +1 px
          nw = nwx * nwy;
          tBuffer[tIndex + 3 * tw + 3] += sR * nw;
          tBuffer[tIndex + 3 * tw + 4] += sG * nw;
          tBuffer[tIndex + 3 * tw + 5] += sB * nw;
        }
      } // end for sx
    } // end for sy

    // create result canvas
    var resCV = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;
    var resCtx = resCV.getContext('2d');
    var imgRes = resCtx.getImageData(0, 0, tw, th);
    var tByteBuffer = imgRes.data;
    // convert float32 array into a UInt8Clamped Array
    var pxIndex = 0; //
    for (
      sIndex = 0, tIndex = 0;
      pxIndex < tw * th;
      sIndex += 3, tIndex += 4, pxIndex++
    ) {
      tByteBuffer[tIndex] = Math.ceil(tBuffer[sIndex]);
      tByteBuffer[tIndex + 1] = Math.ceil(tBuffer[sIndex + 1]);
      tByteBuffer[tIndex + 2] = Math.ceil(tBuffer[sIndex + 2]);
      tByteBuffer[tIndex + 3] = 255;
    }
    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);
    return resCV.toDataURL(type);
  }

  uniqueArray(arr) {
    let hash = {};
    let result = [];
    arr.forEach(item => (hash[item] = 1));
    for (let i in hash) {
      if (hash.hasOwnProperty(i) && hash[i] === 1) {
        result.push(i);
      }
    }
    return result;
  }

  compareArray(arr1: Array<any>, arr2: Array<any>) {
    if (!arr1 || !arr2) {
      return false;
    }
    if (arr1.length != arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] != arr2[i]) {
        return false;
      }
    }
    return true;
  }

  formatCurrency(number) {
    if (!number) {
      return '0';
    }
    return number
      .toFixed()
      .replace(/./g, (c, i, a) =>
        i && c !== ',' && (a.length - i) % 3 === 0 ? '.' + c : c
      );
  }

  fulfillmentShippingStateMap(status) {
    return {
      null: 'Chưa xử lý',
      default: 'Đã tạo',
      created: 'Đã tạo',
      confirmed: 'Đã xác nhận',
      unknown: 'Không xác định',
      processing: 'Đang xử lý',
      picking: 'Đang lấy hàng',
      holding: 'Chờ giao',
      delivering: 'Đang giao hàng',
      returning: 'Đang trả hàng',
      delivered: 'Đã giao hàng',
      returned: 'Đã trả hàng',
      undeliverable: 'Không giao được',
      cancelled: 'Đã hủy',
      closed: 'Hoàn tất',
      error: 'Lỗi vận đơn'
    }[status];
  }

  orderSourceMap(source, partner_name?) {
    return source
      ? {
          ts_app: 'Top Ship App',
          unknown: 'Không xác định',
          default: 'Mặc định',
          etop_pos: 'Etop POS',
          haravan: 'Haravan',
          etop_pxs: 'Etop POS Extension',
          self: 'Nhập hàng',
          import: 'Import',
          etop_cmx: partner_name,
          api: 'API'
        }[source]
      : 'Không xác định';
  }

  orderGhnNoteMap(code) {
    return {
      null: '',
      CHOTHUHANG: 'Cho thử hàng',
      CHOXEMHANGKHONGTHU: 'Cho xem hàng không thử',
      KHONGCHOXEMHANG: 'Không cho xem hàng'
    }[code];
  }

  shippingFeeShopMap(fee) {
    return {
      main: 'Phí giao hàng',
      return: 'Phí trả hàng',
      adjustment: 'Phí vượt cân',
      insurance: 'Phí bảo hiểm',
      tax: 'Thuế',
      cods: 'Phí thu hộ (COD)',
      address_change: 'Phí đổi thông tin',
      discount: 'Giảm giá',
      other: 'Phụ phí khác',
      unknown: 'Không xác định'
    }[fee]
  }

  getStatusDisplay(status) {
    switch (status) {
      case 'Open':
        return 'Mới';
      case 'Confirmed':
        return 'Đã tiếp nhận';
      case 'In Progress':
        return 'Đang xử lý';
      case 'Wait For Response':
        return 'Chờ Shop phản hồi';
      case 'Closed':
        return 'Đóng';
    }
    return status;
  }

  subStatusMap(substatus) {
    return substatus
      ? {
        reject: 'Từ chối xử lý',
        failed: 'Xử lý thất bại',
        success: 'Xử lý thành công'
      }[substatus]
      : '';
  }

  filterOperatorMap(clientOp, raw = false) {
    let map = {
      '==': '=',
      '&=': 'c'
    };
    return raw ? clientOp : map[clientOp] || clientOp;
  }

  providerLogoSm(fulfillment) {
    const providerLogoSm = {
      ghn: 'ghn-s.png',
      ghtk: 'ghtk-s.png',
      vtpost: 'vtpost-s.png',
      default: 'placeholder_medium.png'
    };
    try {
      const { shipping_provider } = fulfillment;
      return providerLogoSm[shipping_provider];
    } catch (e) {
      return providerLogoSm.default;
    }
  }

  matchKeyArray(obj, spec) {
    for (let key of Object.keys(obj)) {
      if (obj.hasOwnProperty(key)) {
        let specs = obj[key];
        let check = specs.some(s => s == spec);
        if (check) {
          return key;
        }
      }
    }
  }

  lowerCase(string) {
    return string.toLowerCase();
  }

  isNumber(value: string | number): boolean {
    return ((value != null) && !isNaN(Number(value.toString())));
  }

  encodeSVG(svgText: string): string {
    let encoded = svgText.replace(/\s+/g, ' ');

    // According to Taylor Hunt, lowercase gzips better ... my tiny test confirms this
    encoded = this.replaceAll(encoded, "%", "%25");
    encoded = this.replaceAll(encoded, "> <", "><"); // normalise spaces elements
    encoded = this.replaceAll(encoded, "; }", ";}"); // normalise spaces css
    encoded = this.replaceAll(encoded, "<", "%3c");
    encoded = this.replaceAll(encoded, ">", "%3e");
    encoded = this.replaceAll(encoded, "\"", "'");
    encoded = this.replaceAll(encoded, "#", "%23"); // needed for ie and firefox
    encoded = this.replaceAll(encoded, "{", "%7b");
    encoded = this.replaceAll(encoded, "}", "%7d");
    encoded = this.replaceAll(encoded, "|", "%7c");
    encoded = this.replaceAll(encoded, "^", "%5e");
    encoded = this.replaceAll(encoded, "`", "%60");
    encoded = this.replaceAll(encoded, "@", "%40");

    return encoded
  }

  escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  compareProductPrice(product: any) {
    if (!product.variants?.length) {
      return this.formatCurrency(product.price) + 'đ';
    }
    let prices = [];
    product.variants.forEach(v => {
      prices.push(v.retail_price);
    })
    let min = Math.min.apply(null, prices);
    let max = Math.max.apply(null, prices);
    if (product.variants.length == 1 || min == max) {
      return this.formatCurrency(product.variants[0].retail_price) + 'đ';
    } else {
      return this.formatCurrency(min) + 'đ - ' + this.formatCurrency(max) + 'đ';
    }
  }
}
