<div class="wrapper-register" *ngIf="!loadingView; else hasLoading">
    <div class="register-body">
        <div class="l-side d-none d-sm-flex flex-column">
            <div class="d-flex m-4">
                <img class="logo" src="{{getAssets()?.logo}}" alt="logo">
            </div>
            <div class="d-flex flex-column justify-content-center m-auto">
                <div class="text-center">
                    <h3 class="title mb-4">
                        Chào mừng bạn đến <span class="title primary-color">{{getAppName()}}!</span>
                    </h3>
                    <h4 class="subtitle text-dark">
                        Nền tảng Giao tiếp Đa kênh kết nối các Nhà mạng & Zalo, giúp Doanh nghiệp triển khai Zalo Official Account (OA) nhanh chóng bao gồm OA Chat, ZNS, ZCC & Zalo Mini App
                    </h4>
                </div>
                <div class="d-flex justify-content-center">
                    <img class="feature-img" src="{{getAssets()?.banner}}" alt="">
                </div>
            </div>
        </div>
        <div class="r-side d-flex flex-column">
            <div class="d-flex d-sm-none m-4">
                <img class="logo" src="{{getAssets()?.logo}}" alt="logo">
            </div>
            <ng-container *ngIf="currentView(view.PHONE_SUBMIT)">
                <div class="d-flex flex-column">
                    <div class="register-form px-5">
                        <div class="title">
                            <strong class="text-larger text-gray">Xin chào,</strong>
                        </div>
                        <div class="info">
                            <p class="text-big text-gray">
                                Vui lòng nhập số điện thoại để bắt đầu trải nghiệm các tiện ích MIỄN PHÍ!
                            </p>
                        </div>
                        <div>
                            <div class="form-group">
                                <input (keyup.enter)="onPhoneSubmit()" type="text" class="form-control rounded-pill" placeholder="Nhập số điện thoại" name="phone" autofocus [(ngModel)]="signupData.phone">
                            </div>
                            <button class="btn rounded-pill" (click)="onPhoneSubmit()" [disabled]="loading">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i>
                Tiếp tục
                </button>
                        </div>
                    </div>
                </div>
                <div class="mt-auto border-top p-4 text-white d-flex align-items-center justify-content-center">
                    <span>Bạn đã có tài khoản?</span>
                    <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.LOGIN)">
                        Đăng nhập
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="currentView(view.PHONE_VERIFY)">
                <div class="d-flex flex-column">
                    <div class="register-form px-5">
                        <div class="title">
                            <strong class="text-larger text-gray">Xác nhận số điện thoại</strong>
                        </div>
                        <div class="info">
                            <p class="text-big text-gray">
                                Một mã xác nhận đã được gửi đến Zalo số <strong class="font-13">{{signupData.phone}}</strong>, vui lòng kiểm tra thông báo trong ứng dụng Zalo và nhập mã vào ô bên dưới.
                            </p>
                        </div>
                        <div>
                            <div class="form-group position-relative">
                                <div class="position-absolute text-white changephone-text" (click)="toView(view.PHONE_SUBMIT)">
                                    Thay đổi
                                </div>
                                <input class="form-control rounded-pill" value="{{ signupData.phone }}" readonly>
                            </div>
                            <div class="form-group">
                                <input (keyup.enter)="onPhoneVerify()" class="form-control rounded-pill" placeholder="Nhập mã xác nhận" name="verify_code" autofocus [(ngModel)]="verify_code">
                            </div>
                            <button class="btn btn-register rounded-pill" (click)="onPhoneVerify()" [disabled]="loading || !verify_code">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i>
                Xác nhận
                </button>
                            <div class="resend-code text-white pt-3 px-2">
                                <span>Bạn không nhận được mã? </span>
                                <span style="opacity: 0.5" *ngIf="countdown > 0"><strong>Gửi lại </strong>(vui lòng chờ trong {{countdown}} giây)</span>
                                <strong class="text-white" style="cursor: pointer" *ngIf="countdown == 0" (click)="reSendVerifyPhone()">Gửi
                    lại</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-auto border-top p-4 text-white d-flex align-items-center justify-content-center">
                    <span>Bạn đã có tài khoản?</span>
                    <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.LOGIN)">
                        Đăng nhập
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="currentView(view.LOGIN)">
                <div class="d-flex flex-column ">
                    <form class="register-form px-5" (submit)="onLogin()">
                        <div class="title">
                            <strong class="text-larger text-gray">Chào mừng bạn đã trở lại!</strong>
                        </div>
                        <div class="info">
                            <p class="text-big text-gray">
                                Vui lòng nhập mật khẩu để truy cập công ty.
                            </p>
                        </div>
                        <div>
                            <div class="form-group">
                                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập số điện thoại hoặc email" name="login" [(ngModel)]="signupData.phone">
                            </div>
                            <div class="form-group position-relative">
                                <div class="position-absolute changephone-text" (mousedown)="showPassword()" (mouseup)="hidePassword()" *ngIf="password">
                                    Hiển thị
                                </div>
                                <input type="password" class="form-control rounded-pill" name="password" id="password" placeholder="Nhập mật khẩu" [(ngModel)]="password">
                            </div>
                            <button class="btn btn-register rounded-pill mb-3" type="submit" [disabled]="loading">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i>
                Đăng nhập
                </button>
                            <span class="pl-2 text-white forgot" style="cursor: pointer" (click)="forgotPassword()">
                Quên mật khẩu?
                </span>
                        </div>
                    </form>
                </div>
                <div class="mt-auto border-top p-4 text-white d-flex align-items-center justify-content-center" *ngIf="allowRegister()">
                    <span>Bạn chưa có tài khoản?</span>
                    <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.PHONE_SUBMIT)">
                        Đăng ký
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="currentView(view.CREATE_SHOP)">
                <div class="d-flex flex-column h-100">
                    <div class="register-form px-5">
                        <div class="title">
                            <strong class="text-larger text-gray">Xin chào, {{userName}} </strong>
                        </div>
                        <div class="info">
                            <p class="text-big text-gray">
                                Vui lòng tạo công ty để bắt đầu sử dụng.
                            </p>
                        </div>
                        <form (submit)="createShop()">
                            <div class="form-group">
                                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập tên công ty" name="fullname" [(ngModel)]="signupData.shop_name">
                            </div>
                            <div class="form-group text-white">
                                <button class="btn btn-register rounded-pill" type="submit" [disabled]="loading">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i>
                    Tiếp tục
                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentView(view.REGISTER)">
                <div class="d-flex flex-column">
                    <div class="register-form px-5">
                        <div class="title">
                            <strong class="text-larger text-gray">Tạo tài khoản</strong>
                        </div>
                        <div class="info">
                            <p class="text-big text-gray">
                                Vui lòng sử dụng thông tin chính xác để có được trải nghiệm tốt nhất trong quá trình sử dụng.
                            </p>
                        </div>
                        <form (submit)="signUp()">
                            <div class="form-group position-relative">
                                <div class="position-absolute text-white changephone-text" (click)="toView(view.PHONE_SUBMIT)">
                                    Thay đổi
                                </div>
                                <input type="text" class="form-control rounded-pill" name="phone" [(ngModel)]="signupData.phone" readonly>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập họ tên" name="fullname" [(ngModel)]="signupData.full_name">
                            </div>
                            <div class="form-group position-relative">
                                <input type="email" class="form-control rounded-pill" placeholder="Nhập email" name="email" [(ngModel)]="signupData.email">
                            </div>
                            <div class="form-group" *ngIf="!invitation">
                                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập tên công ty" name="fullname" [(ngModel)]="signupData.shop_name">
                            </div>
                            <div class="form-group position-relative">
                                <div class="position-absolute changephone-text" (mousedown)="showPassword()" (mouseup)="hidePassword()" *ngIf="password">
                                    Hiển thị
                                </div>
                                <input type="password" class="form-control rounded-pill" id="password" placeholder="Nhập mật khẩu" name="password" [(ngModel)]="signupData.password">
                            </div>
                            <div class="form-group position-relative">
                                <input type="password" class="form-control rounded-pill" placeholder="Nhập lại mật khẩu" name="confirm" [(ngModel)]="signupData.confirm">
                            </div>
                            <div class="form-group text-white">
                                <div class="py-3">
                                    <div class="form-check mb-2">
                                        <label class="form-check-label">
                        <input required type="checkbox" class="form-check-input" name="agree-tos" [(ngModel)]="signupData.agree_tos">
                        Bằng việc đăng ký tôi đồng ý với
                        <a href="{{policyUrl()}}" class="provision text-white font-weight-bold" target="_blank">
                        điều khoản sử dụng dịch vụ
                        </a>
                    </label>
                                    </div>
                                </div>
                                <button class="btn btn-register rounded-pill" type="submit" [disabled]="loading">
                    <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i>
                    Tạo tài khoản
                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="mt-auto p-4 text-white d-flex align-items-center justify-content-center">
                    <span>Bạn đã có tài khoản?</span>
                    <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.LOGIN)">
                        Đăng nhập
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #hasLoading>
</ng-template>
