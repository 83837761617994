import { BaseModel } from '../core/base/base-model';

export type UserSource = 'unknown' | 'psx' | 'etop' | 'topship' | 'ts_app_android'
  | 'ts_app_ios' | 'ts_app_web' | 'partner' | 'etop_app_android' | 'etop_app_ios' | 'telecom';

export class User extends BaseModel {
  id: string;
  full_name: string;
  short_name: string;
  created_at: string;
  updated_at: string;

  email: string;
  email_verification_sent_at: string;
  email_verified_at: string;

  ref_aff: string;
  ref_sale: string;

  phone: string;
  phone_verification_sent_at: string;
  phone_verified_at: string;

  is_blocked: boolean;
  block_reason: string;
  source: UserSource;
  source_display: string;
  total_shop: number;
}

export class LastUserOTP extends BaseModel {
  action: string;
  label: string;
  otp: string;
}
